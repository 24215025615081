import { combineEpics, ofType } from 'redux-observable'
import { from, of, combineLatest } from 'rxjs'
import { catchError, switchMap, map } from 'rxjs/operators'
import { Action, Actions } from '../actionTypes'
import { ActionsObservable, AppState, StateObservable } from '..'
import { selectAnalyticsState } from './selectors'
import {
  analyticsReady,
  fireCohortEventAction,
  initializeCohortEvent,
  pageAnalyticsFailed,
  pageAnalyticsSuccess,
  updateAnalyticsProperties,
  updateAnalyticsPropertiesReady,
} from './actions'
import { FETCH_STAC_FLAGS_SUCCESS } from '../cohorts/actionTypes'
import { fireCohortingEvent, fireProtoPageView } from 'src/modules/analytics/v3'
import { SET_MARKET_SUCCESS } from '../market/actionTypes'

const analyticsReadyEpic = (action$: ActionsObservable): any => {
  const deviceReady$ = action$.pipe(ofType(Actions.DEVICE_READY))
  const stacFlagsInitialized$ = action$.pipe(ofType(FETCH_STAC_FLAGS_SUCCESS))
  const marketReady$ = action$.pipe(ofType(SET_MARKET_SUCCESS))

  return combineLatest([marketReady$, deviceReady$, stacFlagsInitialized$]).pipe(
    switchMap(() => from([fireCohortEventAction(), analyticsReady()]))
  )
}
const fireCohortingEventEpic = (action$: ActionsObservable, state$: StateObservable): any =>
  action$.pipe(
    ofType(Actions.FIRE_COHORT_EVENT),
    switchMap(() => {
      const state = state$.value
      const analyticsState = selectAnalyticsState(state)
      const isCohortInitialized = analyticsState.cohortInitialized
      if (isCohortInitialized) return of(initializeCohortEvent())
      fireCohortingEvent(state)

      // @ts-ignore
      return of(initializeCohortEvent())
    })
  )

const sendPageAnalyticsEpic = (action$: ActionsObservable, state$: StateObservable): any =>
  action$.pipe(
    ofType(Actions.ANALYTICS_READY),
    switchMap(() =>
      from(fireProtoPageView(state$.value)).pipe(
        map(analyticsProperties => {
          if (analyticsProperties && 'properties' in analyticsProperties) {
            return [
              updateAnalyticsProperties(analyticsProperties.properties),
              updateAnalyticsPropertiesReady(),
              pageAnalyticsSuccess(),
            ]
          }
          return [pageAnalyticsSuccess()]
        }),
        switchMap(actions => of(...actions)),
        catchError(error => {
          console.debug(error)
          return of(pageAnalyticsFailed())
        })
      )
    )
  )

export const analyticsEpic = combineEpics<Action, Action, AppState>(
  analyticsReadyEpic,
  fireCohortingEventEpic,
  sendPageAnalyticsEpic
)
