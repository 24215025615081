import React, { useEffect, useState } from 'react'
import LatinoTemplateDefault from 'src/components/template/la/'
import { laDiscountPriceMonthly } from 'src/constants/laPlanData'
import { videos } from 'src/constants/videos'

export default props => {
  return (
    <LatinoTemplateDefault
      location={props.location}
      lang="en"
      market="latino"
      //REMOVED: univision_removed video and annotations
      // video={videos.latino.videoUrl}
      // annotations={videos.latino.annotations}
      //REPLACED: univision_removed poster images
      poster="https://assets-imgx.fubo.tv/marketing/lp/images/posters/LaLiga_Ver1_Web.jpg"
      posterMobile="https://assets-imgx.fubo.tv/marketing/lp/images/posters/LaLiga_Ver1_Mobile.jpg"
      title="Save big on live TV for your family"
      subtitle={`Access now for only $${laDiscountPriceMonthly} the first month`}
      tagline="60+ channels of sports, news, novelas, and entertainment in Spanish."
      networkSet="entertainmentLatino"
      noteLink="/latino-es/"
    />
  )
}
