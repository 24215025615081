import qs from 'qs'
import { StateObservable, combineEpics, ofType } from 'redux-observable'
import { concat, EMPTY, from, of } from 'rxjs'
import { catchError, switchMap, map, filter } from 'rxjs/operators'
import { openZVA, ZVAFailed, ZVAInitialized, ZVALoading, ZVAUpdated, startZVA } from './actions.ts'
import { initZVA, openZVAWithConfig, updateZVA } from './effects.ts'
import { Action, Actions } from '../actionTypes.ts'
import { ActionsObservable, AppState } from '../index.ts'

const helpCenterUrl = {
  us: 'https://support.fubo.tv/hc',
  canada: 'https://support.fubo.tv/hc/en-ca',
  all: 'https://support.fubo.tv/hc',
  latino: 'https://support.fubo.tv/hc',
}

const getConfig = (state$: StateObservable<AppState>) => {
  const { market } = state$.value
  return {
    authenticated: false,
    location_info: {
      current_country: market.countryCode,
      current_zip: market.postalCode,
    },
  }
}

/**
 * Starts zva if the zva param is present.
 * We need this so zva can push the user to a new location and we can
 * "keep it open" when we are essentially reinitializing the app entirely.
 *
 * Remove the param once zva launches because we have no way of detecting
 * if ZVA is closed and don't want a page refresh later to automatically open ZVA.
 */
const startZvaOnAppReadyEpic = (action$: ActionsObservable): any =>
  action$.pipe(
    ofType(Actions.ANALYTICS_READY),
    switchMap(() => {
      const currentLocation = window?.location
      const currentSearch = currentLocation?.search || ''
      const parsedSearch = qs.parse(currentSearch, {
        ignoreQueryPrefix: true,
      })
      if (parsedSearch.zva !== 'true') {
        return EMPTY
      }
      const currentPathname = currentLocation?.pathname || ''
      const modifiedSearch = qs.stringify({
        ...parsedSearch,
        zva: undefined,
      })
      const redirectUrl = `${currentPathname}${modifiedSearch?.length ? `?${modifiedSearch}` : ''}`
      window.history.replaceState(null, '', redirectUrl)
      return of(startZVA())
    })
  )
const startZvaEpic = (action$: ActionsObservable, state$: StateObservable<AppState>): any =>
  action$.pipe(
    ofType(Actions.START_ZVA),
    switchMap(() => {
      const { zva } = state$.value
      const zvaIsInitialized = zva.isInitialized
      const zvaIsLoading = state$.value.zva.isLoading
      if (zvaIsInitialized) {
        return of(openZVA())
      }
      if (zvaIsLoading) {
        return EMPTY
      }
      const config = getConfig(state$)
      return concat(
        of(ZVALoading()),
        from(initZVA(config)).pipe(
          map(ZVAInitialized),
          catchError(err => of(ZVAFailed(err)))
        )
      )
    })
  )
const openZvaWhenReadyEpic = (action$: ActionsObservable, state$: StateObservable<AppState>): any =>
  action$.pipe(
    ofType(Actions.OPEN_ZVA, Actions.ZVA_INITIALIZED),
    switchMap(() => {
      const config = getConfig(state$)
      return from(openZVAWithConfig(config)).pipe(map(ZVAUpdated))
    })
  )
const triggerZvaUpdateEpic = (action$: ActionsObservable, state$: StateObservable<AppState>): any =>
  action$.pipe(
    ofType(Actions.SET_MARKET_SUCCESS),
    filter(() => state$.value.zva.isInitialized),
    switchMap(() => {
      const config = getConfig(state$)
      return from(updateZVA(config)).pipe(map(ZVAUpdated))
    })
  )
const redirectOnFailedEpic = (action$: ActionsObservable, state$: StateObservable<AppState>): any =>
  action$.pipe(
    ofType(Actions.ZVA_FAILED),
    switchMap(() => {
      // @ts-expect-error - TS2339 - Property 'value' does not exist on type 'typeof StateObservable'.
      const { market } = state$.value.market
      const redirectUrl = helpCenterUrl?.[market]
        ? `${helpCenterUrl[market]}/requests/new`
        : helpCenterUrl.all
      window.open(redirectUrl, '_blank')
      return EMPTY
    })
  )

export const zvaEpic = combineEpics<Action, Action, AppState>(
  startZvaOnAppReadyEpic,
  startZvaEpic,
  openZvaWhenReadyEpic,
  triggerZvaUpdateEpic,
  redirectOnFailedEpic
)
