import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    offerCode="pro-affiliation-15-off"
    offerBanner={
      <>
        ✔ Special offer for PFF fans:
        <br />
        Enjoy 15% off your first month of Fubo.
      </>
    }
    logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-lockup-pff-v3.png?w=451&ch=width&auto=format,compress"
    logoHeight="42px"
    video={videos.pff_nfl_draft.videoUrl}
    title="Watch the PFF Draft Show live"
    subtitle="FOLLOW EVERY PICK FROM THE PRO FOOTBALL DRAFT"
    tagline="Stream it from any device on Fubo Sports Network"
    networkSet="nfl"
    showRedzone={false}
  />
)
