import { routerMiddleware } from 'connected-react-router'
import { some, isString, isRegExp } from 'lodash-es'
import { applyMiddleware, compose } from 'redux'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import { silentActions } from '../reducers/actionTypes'

export type MiddlewareParams = {
  history: any
  epicMiddleware: any
}
function configureMiddleware(params: MiddlewareParams): any[] {
  const middleware = [thunk, params.epicMiddleware, routerMiddleware(params.history)]
  if (process.env.NODE_ENV === `development`) {
    const loggerMiddleware = createLogger({
      collapsed: true,
      predicate: (getState, actionToLog) =>
        !some(silentActions, silentAction => {
          return (
            (isString(silentAction) && actionToLog.type === silentAction) ||
            (isRegExp(silentAction) && actionToLog.type.match(silentAction))
          )
        }),
    })
    middleware.push(loggerMiddleware)
  }
  return middleware
}

// attach redux-devtools-extension if available
const composeEnhancers =
  (typeof window !== 'undefined' &&
    // @ts-expect-error - TS2339 - Property '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__' does not exist on type 'Window & typeof globalThis'.
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    // @ts-expect-error - TS2339 - Property '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__' does not exist on type 'Window & typeof globalThis'.
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 100,
    })) ||
  compose

export const makeEnhancers = (middlewareParams: MiddlewareParams) =>
  composeEnhancers(applyMiddleware(...configureMiddleware(middlewareParams)))
