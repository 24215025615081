import {
  TRY_PAGE_LOAD,
  PAGE_LOAD_SUCCESS,
  PAGE_LOAD_FAIL,
  SET_APP_REFERRER,

} from './actionTypes.ts'

import type { PageLoadFailAction, PageLoadSuccessAction, SetAppReferrerAction, TryPageLoadAction } from './actionTypes.ts'

// Define action creators
export const tryPageLoad = ({
  deviceId,
  referringUrl,
}: {
  deviceId: string
  referringUrl?: string
}): TryPageLoadAction => ({
  type: TRY_PAGE_LOAD,
  payload: { deviceId, referringUrl },
})

export const pageLoadSuccess = (data: any):PageLoadSuccessAction => ({
  type: PAGE_LOAD_SUCCESS,
  payload: data,
})

export const pageLoadFail = (error: any): PageLoadFailAction => ({
  type: PAGE_LOAD_FAIL,
  error: error,
})

export const setAppReferrer = (referrer: string): SetAppReferrerAction => ({
  type: SET_APP_REFERRER,
  referrer,
})

export type ImpactAction =
  | TryPageLoadAction
  | PageLoadSuccessAction
  | PageLoadFailAction
  | SetAppReferrerAction
