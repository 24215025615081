import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import { sportIds, leagueIds, teamIds } from 'src/constants/personalizationIds'

export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    video={videos.new_york_yankees.videoUrl}
    pageTitle="Watch New York Yankees baseball"
    title={
      <>
        Watch New York Yankees<sup>®</sup> baseball
      </>
    }
    subtitle="GET LIVE GAMES, TEAM NEWS & MORE"
    tagline="Stream it all on YES Network from any device. Regional restrictions apply."
    networkSet="baseball"
    customNetworks={['yesnetwork', 'msg']}
    league="MLB"
    logo="https://assets-imgx.fubo.tv/marketing/lp/images/logos/logo-fubo-yes.png?w=451&ch=width&auto=format,compress"
    logoHeight="56px"
    sportId={sportIds.Baseball}
    leagueId={leagueIds.MLB}
    teamId={teamIds['New York Yankees']}
    offerBanner={
      <>
        ✔ Special offer applied! <br />
        Save $30 on your first month of Fubo
      </>
    }
  />
)
