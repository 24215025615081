import React from 'react'
import styled from 'styled-components'
import { defaultCTAURI } from 'src/modules/utils/constants'
import { useSelector } from 'react-redux'
import { selectCTAParams } from 'src/modules/reducers/params'
import { createUrl } from 'src/modules/utils/createUrl'
import { replaceUrlParam } from 'src/modules/utils/replaceUrlParam'
import { fireUIInteractionEvent } from 'src/modules/analytics/v3'
import { selectStateForProtobuf } from 'src/modules/reducers'

const defaultTitle = {
  en: '✔ Special Offer Applied',
  es: '✔ Oferta Especial Aplicada',
}

const Banner = ({ title, subtitle, ctaHrefOverride, lang = 'en', noteText }) => {
  const parsedParams = useSelector(selectCTAParams)
  const stateForProtobuf = useSelector(selectStateForProtobuf)
  let ctaURL = createUrl(defaultCTAURI, parsedParams)
  title =
    typeof title === 'string' && title?.includes('<>')
      ? title.split('<>').map((line, index, array) => (
          <>
            {line}
            {index < array.length - 1 && <br />}
          </>
        ))
      : title

  const bannerTitle = title || defaultTitle[lang]

  const handleOnClick = () => {
    fireUIInteractionEvent(
      {
        component: 'offer_banner_clicked',
      },
      stateForProtobuf
    )
  }

  ctaURL = replaceUrlParam(ctaURL, 'comp', 'offerbanner')

  return (
    <BannerWrapper
      onClick={handleOnClick}
      href={ctaHrefOverride || ctaURL}
      className="offer-banner"
    >
      {noteText && <div className="banner-space">{noteText}</div>}
      <BannerInner>
        <BannerContent>
          <div className="banner-title">{bannerTitle}</div>
          <BannerSubtitle>{subtitle}</BannerSubtitle>
        </BannerContent>
      </BannerInner>
      {noteText && <div className="banner-note">{noteText}</div>}
    </BannerWrapper>
  )
}

export default Banner

const BannerWrapper = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0, 160, 215, 0.95);
  position: sticky;
  color: #fff;
  top: 0;
  padding: 20px 10px 15px;
  z-index: 10000;
  cursor: ${props => (props.onClick ? 'pointer' : 'auto')};

  @media (max-width: 767px) {
    flex-direction: column-reverse;
  }

  .banner-title {
    font-size: 18px;
    line-height: 1.4;
    text-align: center;
    max-width: 400px;
    line-height: 1.6;
    width: 100%;
    font-weight: 700;

    img {
      height: 23px;
      margin-left: 6px;
      margin-right: 6px;
    }

    @media (min-width: 600px) {
      max-width: 100%;
      padding: 0;
    }
  }

  .banner-space {
    visibility: hidden;
    pointer-events: none;
    @media (max-width: 767px) {
      display: none;
    }
  }

  .banner-note {
    font-weight: 700;
    padding-right: 20px;
    @media (max-width: 767px) {
      display: none;
    }
  }
`

const BannerInner = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
`

const BannerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const BannerSubtitle = styled.div`
  font-size: 18px;
  line-height: 1.4;
  text-align: center;
  max-width: 400px;
  line-height: 1.6;
  width: 100%;
  font-weight: 500;

  @media (min-width: 600px) {
    max-width: 100%;
    padding: 0;
  }

  a {
    font-weight: 700;
    font-weight: 700;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`
