import React from 'react'
import { networkLogos } from 'src/constants/networkLogos'
import { offerCodes } from 'src/constants/offerCodes'
import { commonCtaText, USDefaultCtaText } from 'src/modules/utils/constants'

const COMMON_FEATURES = {
  CLOUD_DVR: 'Unlimited Cloud DVR included',
  MULTI_SCREEN: 'Watch on up to 10 screens at once',
  NFL_GAMES: 'Access to NFL games',
}

export const FREE_CHANNELS = '100+'
export const PRO_CHANNELS = '150+'
export const ELITE_CHANNELS = '200+'

const formatTeamsText = (teams = []) => {
  if (!teams?.length) return ''
  return teams.length > 2
    ? `Watch the ${teams[0]}, ${teams[1]}, and more`
    : `Watch the ${teams.join(' and the ')}`
}

export const roundToNearest10 = num => Math.round(num / 10) * 10

export const usPlanData = (channels, fanduelRSNData = null) => {
  const proChannels = channels?.['pro'] ? roundToNearest10(channels['pro']) + '+' : PRO_CHANNELS
  const eliteChannels = channels?.['elite']
    ? roundToNearest10(channels['elite']) + '+'
    : ELITE_CHANNELS
  const freeChannels = channels?.['free'] ? roundToNearest10(channels['free']) + '+' : FREE_CHANNELS
  const isFanduel = fanduelRSNData !== null
  const proPlanValues = isFanduel
    ? {
        items: [
          { available: true, text: `${proChannels} top channels of live TV & sports` },
          {
            available: true,
            text: formatTeamsText(fanduelRSNData?.teams),
          },
          { available: true, text: COMMON_FEATURES.CLOUD_DVR },
          { available: true, text: COMMON_FEATURES.MULTI_SCREEN },
          { available: true, text: COMMON_FEATURES.NFL_GAMES },

          { available: true, text: 'ABC, CBS, FOX, NBC, ESPN & more' },
        ].filter(Boolean),
        disclaimer: <>Regional restrictions apply. Additional taxes and fees may apply.</>,
      }
    : {
        subText: 'Plus taxes and additional fees.',
        subtitle: 'Best for passionate sports fans',
        items: [
          { available: true, text: 'Local NBA, NHL and MLB coverage' },
          { available: true, text: 'Includes national NFL games' },
          { available: true, text: `${proChannels} top TV channels` },
          { available: true, text: 'Unlimited Cloud DVR' },
          { available: true, text: 'Watch on 3 devices at once' },
        ],
        disclaimer: <>*Requires additional fees where available. Regional restrictions apply.</>,
      }
  return {
    pro: {
      promoImg:
        'https://assets-imgx.fubo.tv/marketing/lp/images/card/us/pro-plan-card-us.png?ch=width&auto=format,compress',
      offerCode: offerCodes.pro,
      plan: 'pro',
      title: 'Fubo Pro',
      price: 84.99,
      discountedPrice: 64.99,
      savingsNote: 'Save $20',
      listItems: proPlanValues.items,
      subText: proPlanValues.subText,
      subtitle: proPlanValues.subtitle,
      asterisk: true,
      footnote: proPlanValues.disclaimer,
      networkLogos: [
        { logo: networkLogos.abc.logoWhite, alt: networkLogos.abc.alt, width: 44, height: 44 },
        { logo: networkLogos.fox.logoWhite, alt: networkLogos.fox.alt, width: 55, height: 24 },
        {
          logo: networkLogos.nflnetwork.logoWhite,
          alt: networkLogos.nflnetwork.alt,
          width: 71,
          height: 49,
        },
        { logo: networkLogos.espn.logoUrl, alt: networkLogos.espn.alt, width: 75, height: 19 },
        {
          alt: '+ More',
        },
      ],
      ctaText: USDefaultCtaText,
      footnote: <>$64.99 for first month, $84.99/mo thereafter. Regional restrictions apply.</>,
    },
    essential: {
      promoImg:
        'https://assets-imgx.fubo.tv/marketing/lp/images/card/us/card-og-receiver-cropped.png?w=600&ch=width&auto=format,compress',
      offerCode: offerCodes.essential,
      plan: 'essential',
      title: 'Fubo Essential',
      subtitle: 'Best for football and entertainment fans',
      subText: (
        <>
          Plus taxes. <span style={{ textDecoration: 'underline' }}> No additional fees. </span>
        </>
      ),
      price: 84.99,
      discountedPrice: 59.99,
      savingsNote: 'Save $25',
      listItems: [
        { available: false, text: 'No local NBA, NHL or MLB games' },
        { available: true, text: 'Includes national NFL games' },
        { available: true, text: `${proChannels} top TV channels` },
        { available: true, text: 'Unlimited Cloud DVR' },
        { available: true, text: 'Watch on 3 devices at once' },
      ],
      asterisk: true,
      footnote: <>$59.99 for first month, $84.99/mo thereafter. Regional restrictions apply.</>,
      ctaText: USDefaultCtaText,
    },
    elite: {
      promoImg:
        'https://assets-imgx.fubo.tv/marketing/lp/images/card/us/card-og-receiver-cropped.png?w=600&ch=width&auto=format,compress',
      offerCode: offerCodes.elite,
      plan: 'elite',
      title: 'Fubo Elite',
      price: 94.99,
      discountedPrice: 74.99,
      savingsNote: 'Save $20',
      listItems: [
        { available: true, text: `${eliteChannels} channels` },
        { available: true, text: COMMON_FEATURES.CLOUD_DVR },
        { available: true, text: COMMON_FEATURES.MULTI_SCREEN },
        { available: true, text: '4k included' },
      ],
      asterisk: true,
      footnote: (
        <>
          $74.99 for first month, $94.99/mo thereafter. <br />
          {/* *Paid as a monthly subscription (min. of $89.99/mo.) <br /> */}
          Additional taxes & fees may apply.
        </>
      ),
      ctaText: USDefaultCtaText,
    },
    fanduelsports: {
      promoImg:
        'https://assets-imgx.fubo.tv/marketing/lp/images/card/fanduel-sports-plan-card-bg.png?ch=width&auto=format,compress',
      offerCode: fanduelRSNData?.offerCode,
      title: 'FanDuel Sports Network',
      plan: fanduelRSNData?.package,
      price: fanduelRSNData?.price,
      savingsNote: true,
      listItems: [
        { available: true, text: `${freeChannels} free channels of live TV` },
        {
          available: true,
          text: `Watch the ${
            fanduelRSNData?.teams?.length > 2
              ? `${fanduelRSNData.teams[0]}, ${fanduelRSNData.teams[1]}, and more`
              : fanduelRSNData?.teams?.join(' and the ')
          }`,
        },
        { available: true, text: COMMON_FEATURES.CLOUD_DVR },
        { available: true, text: COMMON_FEATURES.MULTI_SCREEN },
        { available: false, text: COMMON_FEATURES.NFL_GAMES },
        { available: false, text: 'ABC, CBS, FOX, NBC, ESPN & more' },
      ],
      asterisk: false,
      footnote: <>Regional restrictions apply. Additional taxes and fees may apply.</>,
      networkLogos: fanduelRSNData?.rsn && [
        ...fanduelRSNData.rsn.map(network => ({
          logo: networkLogos[network.toLowerCase().replace(/\s+/g, '_')]?.logoWhite,
          alt: network,
          width: 135,
          height: 48,
        })),
      ],
      ctaText: commonCtaText.startYourFreeTrial,
    },
  }
}
