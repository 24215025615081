import React, { useEffect, useState } from 'react'
import LatinoTemplateDefault from 'src/components/template/la/'
import { laDiscountPriceMonthly } from 'src/constants/laPlanData'
import { videos } from 'src/constants/videos'

export default props => {
  return (
    <LatinoTemplateDefault
      location={props.location}
      lang="es"
      market="latino"
      //REMOVED: univision_removed video and annotations
      // video={videos.latino.videoUrl}
      // annotations={videos.latino.annotations}
      //REPLACED: univision_removed poster images
      poster="https://assets-imgx.fubo.tv/marketing/lp/images/posters/LaLiga_Ver1_Web.jpg"
      posterMobile="https://assets-imgx.fubo.tv/marketing/lp/images/posters/LaLiga_Ver1_Mobile.jpg"
      title="Ahorra en TV en vivo para toda la familia"
      subtitle={`Accede ahora por solo $${laDiscountPriceMonthly} el primer mes`}
      tagline="Más de 60 canales con deportes, noticias, novelas y entretenimiento en español."
      networkSet="entertainmentLatino"
      noteLink="/latino/"
    />
  )
}
