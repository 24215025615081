import { createSelector } from 'reselect'


import {
  WEB_MARKETING_ADD_ONS_SECTION,
  WEB_MARKETING_PROMO_BANNER_US,
  WEB_MARKETING_FANDUEL_PLAN_CARDS,
  WEB_MARKETING_FAQ_MESSAGING,
  WEB_MARKETING_INCLUDE_CRTC,
  WEB_MARKETING_MATCHTICKER_PLACEMENT,
} from './constants'
import { StacFlags } from './flags'

import type { BooleanSelector, StacFlagName, StringSelector } from './types'
import { StacFlagsState } from './reducer'


export const selectStacFlagsState = (state): StacFlagsState => state.cohorts


export const selectStacFlagsReady: (state) => boolean =
  createSelector(
    selectStacFlagsState,
    ({ isInitialized, isFetching }) => isInitialized && !isFetching
  )

export const selectAllStacFlags: (arg1) => StacFlags = createSelector(
  selectStacFlagsState,
  (local) => local?.flags
)

// allows passing of flagName and sets it as a cacheKey
const selectStacFlagName = <T>(_: T, flagName: StacFlagName): StacFlagName =>
  flagName

// @ts-expect-error
export const selectStacFlagValue: <T>(
  arg1,
  arg2: StacFlagName
) => T | null = createSelector(
  selectAllStacFlags,
  selectStacFlagName,
  (flags: StacFlags, flagName: StacFlagName) => {
    const flag = flags?.[flagName]
    return flag ? flag.value : null
  }
)

export const selectFAQMessagingFlag: StringSelector = (state) =>
  selectStacFlagValue(state, WEB_MARKETING_FAQ_MESSAGING)


export const selectShowFanduelPlanCardsFlag: BooleanSelector  = createSelector(
    state => selectStacFlagValue(state, WEB_MARKETING_FANDUEL_PLAN_CARDS),
    (value) => value === 'test'
  )
export const selectShowAddOnsSectionFlag: BooleanSelector = createSelector(
  state => selectStacFlagValue(state, WEB_MARKETING_ADD_ONS_SECTION),
  (value) => value === 'test'
)

export const selectPromoBannerUSFlag: BooleanSelector = 
  state => selectStacFlagValue(state, WEB_MARKETING_PROMO_BANNER_US)

export const selectIncludeCRTCFlag: BooleanSelector = 
  createSelector(
    state => selectStacFlagValue(state, WEB_MARKETING_INCLUDE_CRTC),
    (value) => value === 'test'
  )

export const selectMatchTickerPlacementFlag: BooleanSelector = 
  createSelector(
    state => selectStacFlagValue(state, WEB_MARKETING_MATCHTICKER_PLACEMENT),
    (value) => value === 'test'
  )
