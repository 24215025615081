import { imgIX } from 'src/modules/utils/imgIX'
import LazyImage from '../LazyLoad/LazyImage'

const Image = ({ src, isLazyLoad = false, isCompress = true, ...rest }) => {
  const imgSrc = imgIX(src, { isCompress, ...rest })
  if (isLazyLoad) {
    return <LazyImage src={imgSrc} {...rest} />
  }
  return <img src={imgSrc} {...rest} />
}

export default Image
