import {
  fetchChannelsByLocation,
  Params as FetchChannelsByLocationParams,
} from '@fubotv/js-client/dist/esm/domains/UP/channels/fetchChannelsByLocation'

import {
  FETCH_CHANNELS_BY_LOCATION_FAIL,
  FETCH_CHANNELS_BY_LOCATION_SUCCESS,
  FetchChannelsByLocationFailAction,
  FetchChannelsByLocationSuccessAction,
  FetchChannelsPayload,
  TryFetchChannelsByLocationAction,
} from '../actionTypes/'
import { ApiError } from 'src/modules/utils/types/domain.ts'
import { call } from '../../api-client'

export const tryFetchChannelsByLocation = (
  params: FetchChannelsByLocationParams
): TryFetchChannelsByLocationAction => {
  return async dispatch => {
    try {
      const data = await call(fetchChannelsByLocation, params)
      await dispatch(fetchChannelsByLocationSuccess(data))
    } catch (error) {
      if (error.response) {
        dispatch(fetchChannelsByLocationFail(error.response))
      } else if (error.request) {
        dispatch(fetchChannelsByLocationFail(error.request))
      } else {
        dispatch(fetchChannelsByLocationFail(error.message))
      }
    }
  }
}

export const fetchChannelsByLocationSuccess = (
  payload: FetchChannelsPayload
): FetchChannelsByLocationSuccessAction => ({
  type: FETCH_CHANNELS_BY_LOCATION_SUCCESS,
  payload,
})

export const fetchChannelsByLocationFail = (
  error: ApiError
): FetchChannelsByLocationFailAction => ({
  type: FETCH_CHANNELS_BY_LOCATION_FAIL,
  error,
})
