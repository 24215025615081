import {
  INIT_STAC_FLAGS,
  TRY_FETCH_STAC_FLAGS,
  FETCH_STAC_FLAGS_SUCCESS,
  FETCH_STAC_FLAGS_FAILED,
} from './actionTypes'

import type {
  InitStacFlagsAction,
  FetchStacFlagsSuccessAction,
  FetchStacFlagsFailedAction,
} from './actionTypes'
import { initValues,  StacFlagKeys } from './flags'
import type { StacFlagsMap } from './types'


export const mappedFlags = (
  flags ,
  urlFlags
) =>Object.keys(flags || {}).reduce((acc, flagName) => {
    // apply url override flags
    const urlFlag = urlFlags?.[flagName]
    if (urlFlag) {
      acc[flagName] = {
        value: mapUrlFlagValue(urlFlag),
        key: flagName,
        ttl: -1,
      }
      return acc
    }

    // updated supported flags with response values
    if (initValues[flagName]) {
      const { key, found, value, scope } = flags[flagName]
      const ttl = scope?.ttl ?? -1
      acc[flagName] =
        found && value
          ? {
              value: mapUrlFlagValue(value.value),
              key,
              ttl,
            }
          : initValues[flagName]
    }
    return acc
  }, {})

function mapUrlFlagValue(raw) {
  switch (raw) {
    case 'true':
      return true
    case 'false':
      return false
    default:
      return raw
  }
}


export function initStacFlags(query: any, deviceId?: string): InitStacFlagsAction {
  return {
    type: INIT_STAC_FLAGS,
    query,
    deviceId
  }
}

// This starts fetching flags
export function tryFetchStacFlags(
  keys?: StacFlagKeys,
  query?: any | null ,
  deviceId?: string
): TryFetchStacFlagsAction {
  return {
    type: TRY_FETCH_STAC_FLAGS,
    keys,
    query,
    deviceId
  }
}

// This maps the flags
export function fetchStacFlagsSuccess(
  flags: StacFlagsMap,
  deviceId?: string
): FetchStacFlagsSuccessAction {
  return {
    type: FETCH_STAC_FLAGS_SUCCESS,
    flags,
  }
}
export function fetchStacFlagsFailed(): FetchStacFlagsFailedAction {
  return {
    type: FETCH_STAC_FLAGS_FAILED,
  }
}
