import React, { useState } from 'react'
import { networkLogos } from 'src/constants/networkLogos'
import styled from 'styled-components'
import LazyImage from '../LazyLoad/LazyImage'
import { selectCTAParams } from 'src/modules/reducers/params'
import { useSelector } from 'react-redux'
import { createUrl } from 'src/modules/utils/createUrl'
import { defaultCTAURI } from 'src/modules/utils/constants'
import { replaceUrlParam } from 'src/modules/utils/replaceUrlParam'
import { selectStateForProtobuf } from 'src/modules/reducers'
import { fireUIInteractionEvent } from 'src/modules/analytics/v3'
import { useIntersectionObserver } from 'src/modules/hooks/useIntersectionObserver'
const AddOnTypes = ['sports', 'entertainment', 'channels']

const AddOnCategories = {
  default: {
    sports: [
      {
        logo: networkLogos.mlbtv.logoUrl,
        price: 29.99,
        description:
          'The home of streaming baseball. Stream every out-of-market MLB game live or on demand.',
        title: 'MLB.TV',
        length: 'monthly',
        addOnCode: 'mlb',
      },
      {
        logo: networkLogos.nflredzone.logoUrl,
        price: 10.99,
        description:
          'One low price for NFL RedZone from NFL Network and other premium channels covering a variety of sports.',
        title: 'Sports Plus with NFL RedZone',
        length: 'monthly',
        addOnCode: 'fubotv-basic-deportes-extra',
      },
      {
        logo: networkLogos?.nbaleaguepass?.logoUrl,
        price: 16.99,
        description:
          'Stream up to 40 out-of-market games a week. Follow your favorite teams and players throughout the season.',
        title: 'NBA League Pass',
        length: 'monthly',
        addOnCode: 'nbalp',
      },
    ],
    entertainment: [
      {
        logo: networkLogos.showtime.logoUrl,
        price: 10.99,
        description:
          'An extensive lineup of live channels plus the entire SHOWTIME® on-demand library, including acclaimed originals, blockbuster movies and select Paramount+ content.',
        title: 'Paramount+ with SHOWTIME®',
        length: 'monthly',
        addOnCode: 'fubotv-basic-showtime',
      },
      {
        logo: 'https://images.fubo.tv/station_logos/MGM-plus_c.png',
        price: 6.99,
        description:
          'Three premium live channels and an on-demand collection of the latest Hollywood hits, iconic classics, acclaimed originals, comedy specials and more.',
        title: 'MGM+',
        length: 'monthly',
        addOnCode: 'epix',
      },
      {
        logo: 'https://images.fubo.tv/station_logos/starz2022_c.png',
        price: 10.99,
        description:
          'The whole family of STARZ live channels, plus on-demand access to all STARZ shows and movies.',
        title: 'STARZ',
        length: 'monthly',
        addOnCode: 'starz',
      },
    ],
    channels: [
      {
        price: 2.99,
        description:
          'Get access to breaking stories from premium news networks. Includes national, international, and business news.',
        title: 'News Plus',
        length: 'monthly',
        addOnCode: 'news-plus',
      },
      {
        price: 7.99,
        description:
          'FOX Nation features a robust content library of 180+ shows and 1000+ hours of original shows, series, and exclusives.',
        title: 'FOX Nation',
        length: 'monthly',
        addOnCode: 'fox-nation',
      },
      {
        price: 19.99,
        description:
          'Spanish-language coverage of live sports. Also includes top shows, and movies.',
        title: 'Latino Plus',
        length: 'monthly',
        addOnCode: 'fubotv-basic-latino',
      },
    ],
  },
}

const breakpoint = 768
const desktopBreak = breakpoint + 'px'
const mobileBreak = breakpoint - 1 + 'px'

const AddOnTabs = ({ active, onActiveTab }) => {
  return (
    <Tabs>
      {AddOnTypes.map(cat => (
        <Tab className={active == cat ? 'active' : ''} onClick={() => onActiveTab(cat)} key={cat}>
          <h4>{cat}</h4>
        </Tab>
      ))}
    </Tabs>
  )
}

const AddOnCards = ({ addOns, isAnimate }) => {
  const parsedParams = useSelector(selectCTAParams)
  let ctaURL = createUrl(defaultCTAURI, parsedParams)
  return (
    <Cards className={isAnimate ? 'animate' : ''}>
      {addOns &&
        addOns.map(({ title, description, logo, price, addOnCode }) => {
          let ctaURLBtn = replaceUrlParam(ctaURL, 'addons[]', addOnCode)
          ctaURLBtn = replaceUrlParam(ctaURLBtn, 'comp', 'add_on_cta')
          return (
            <Card key={title}>
              {logo && (
                <div className="img-container">
                  <LazyImage src={logo} alt={title} />
                </div>
              )}
              <div className="addon-info">
                <div className="info">
                  <p className="title">{title} </p>
                  <p className="description">{description}</p>
                </div>

                <p className="price">${price}/mo</p>
              </div>

              <a className="cta_button" href={ctaURLBtn}>
                Watch with add-on
              </a>
            </Card>
          )
        })}
    </Cards>
  )
}

const AddOnsSection = ({ type = 'default' }) => {
  const [activeTab, setActiveTab] = useState(AddOnTypes[0])
  const [isAnimate, setIsAnimate] = useState(false)
  const stateForProtobuf = useSelector(selectStateForProtobuf)
  const [isInView, setIsInView] = useState(false)

  const trackInView = () => {
    setIsInView(true)
    fireUIInteractionEvent(
      {
        component: 'add_ons_section',
        type: 'scrolled',
      },
      stateForProtobuf
    )
  }
  const sectionRef = useIntersectionObserver({
    callback: trackInView,
    disable: isInView,
  })

  let addOnData = null
  if (activeTab) {
    addOnData = AddOnCategories[type]?.[activeTab]
  }

  const handleActiveTab = tab => {
    setActiveTab(tab)
    setIsAnimate(true)
    setTimeout(() => {
      setIsAnimate(false)
    }, 500)

    fireUIInteractionEvent(
      {
        component: 'add_on_tab',
        element: tab,
        type: 'clicked',
      },
      stateForProtobuf
    )
  }

  return (
    <StyledContainer ref={sectionRef}>
      <h2>Watch more with Add-ons</h2>
      <AddOnTabs active={activeTab} onActiveTab={handleActiveTab} />
      <AddOnCards isAnimate={isAnimate} addOns={addOnData} />
    </StyledContainer>
  )
}
export default AddOnsSection

const StyledContainer = styled.section`
  padding: 40px 20px;
  border-top: 1px solid rgb(218, 218, 218);
  margin-top: 60px;
  h2 {
    color: rgb(36, 37, 38);
    text-align: center;
    font-size: 25px;
    margin: 40px auto;

    @media (min-width: ${desktopBreak}) {
      font-size: 38px;
    }
  }
`

const Tabs = styled.div`
  margin: 0 auto 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  max-width: 450px;
  position: relative;
  &:after {
    content: ' ';
    position: absolute;
    left: 0;
    bottom: 0;
    color: rgb(64, 64, 64);
    width: 100%;
    height: 2px;
  }
`

const Tab = styled.div`
  padding: 0 15px;
  cursor: pointer;
  h4 {
    position: relative;
    padding-bottom: 8px;
    text-transform: capitalize;
    color: rgb(64, 64, 64);
    opacity: 0.65;
    font-size: 19px;
    font-weight: 700;
    &:hover {
      color: rgb(224, 49, 0);
      opacity: 1;
    }
  }

  &.active {
    h4 {
      opacity: 1;
      &:after {
        content: '';
        left: 0;
        position: absolute;
        bottom: 0;
        background: linear-gradient(147deg, rgb(250, 139, 110) 3.43%, rgb(255, 58, 3) 85.36%);
        width: 100%;
        height: 3px;
      }
    }
  }
`

const Cards = styled.div`
  @keyframes fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
  display: flex;
  justify-content: space-between;
  max-width: 1240px;
  margin: 0 auto;
  flex-wrap: wrap;
  opacity: 1;
  &.animate {
    opacity: 0;
    animation: fade-in 0.65s forwards;
  }

  @media (min-width: ${desktopBreak}) {
    justify-content: center;
  }
`
const Card = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  padding: 14px 15px;
  max-width: 400px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0 auto 40px;

  .addon-info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    .info {
      flex-grow: 1;
      margin-bottom: 10px;
    }
    p {
      margin: 0 0 12px;
      font-size: 20px;
      line-height: 1.4;
    }
  }
  .title {
    font-weight: bold;
  }
  .img-container {
    margin-bottom: 15px;

    img {
      height: 100px;
      width: 150px;
      object-fit: contain;
      object-position: 50% 50%;
      margin: 0 auto;
    }
  }

  .cta_button {
    position: relative;
    display: inline-block;
    color: rgb(255, 255, 255);
    text-align: center;
    font-style: normal;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.56px;
    padding: 15px 20px;
    white-space: nowrap;
    background: rgb(224, 49, 0);
    border-radius: 4px;
    box-sizing: border-box;
    cursor: pointer;
    margin: 0 auto;
  }
`
