import {
  combineEpics,
  ofType,
} from 'redux-observable'
import { EMPTY, from, of, timer, merge } from 'rxjs'
import { catchError, concatMap, switchMap, map } from 'rxjs/operators'

import {
  tryFetchStacFlags,
  fetchStacFlagsSuccess,
  fetchStacFlagsFailed,
} from './actions'
import { doGetStacFlags } from './effects'
import { mappedFlags } from './utils'

import type { StacFlagKeys } from './flags'
import { Action, Actions } from '../actionTypes'
import { ActionsObservable, AppState, StateObservable } from '..'
import { parseFlagsQueryParams } from 'src/modules/utils/parseQueryParams'

const initStacFlagsEpic = (
  action$: ActionsObservable,
  state$: StateObservable
): any => { 
  return action$.pipe(
    ofType(Actions.INIT_STAC_FLAGS),
    switchMap((action: any) => of(tryFetchStacFlags(undefined, action.query, action.deviceId)))
  )
}
  

const fetchStacFlagsEpic = (action$: ActionsObservable): any =>{
  return  action$.pipe(
    ofType(Actions.TRY_FETCH_STAC_FLAGS),
    concatMap((action: any) =>{
      return from(doGetStacFlags(action.keys, action.deviceId)).pipe(
        map((response) =>{
          const sdFlags = parseFlagsQueryParams(action.query)
          return fetchStacFlagsSuccess(
            mappedFlags(response?.data?.results, sdFlags)
          )
        }),
        catchError(() => of(fetchStacFlagsFailed()))
      )
    })
  )
}
const refetchStacFlagsOnTTLEpic = (
  action$,
  state$,
  scheduler
): any =>
  action$.pipe(
    ofType(Actions.FETCH_STAC_FLAGS_SUCCESS),
    concatMap(({ flags }: { flags: any }) => {
      const flagTTLs = Object.keys(flags).reduce((acc, flagName) => {
        const { key, ttl } = flags[flagName]
        if (ttl > 0) {
          if (!acc.has(ttl)) acc.set(ttl, [])
          acc.get(ttl).push(key)
        }
        return acc
      }, new Map<number, StacFlagKeys>())

      if (flagTTLs.size > 0) {
        const fetchTtlFlagObservables: any[] = []
        flagTTLs.forEach((flagKeys, ttl) => {
          fetchTtlFlagObservables.push(
            timer(ttl, scheduler).pipe(
              map(() => tryFetchStacFlags(flagKeys as StacFlagKeys))
            )
          )
        })
        return merge(...fetchTtlFlagObservables)
      }

      return EMPTY
    })
  )

export const stacFlagsEpic = combineEpics<Action, Action, AppState>(
  initStacFlagsEpic,
  fetchStacFlagsEpic,
  refetchStacFlagsOnTTLEpic
)
