import React from 'react'
import FallingSnow from 'src/components/common/FallingSnow'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import styled from 'styled-components'

export default props => {
  return (
    <StyledContainer>
      <FallingSnow />
      <USTemplateDefault
        location={props.location}
        lang="en"
        market="us"
        video={videos.seasonal_us.videoUrl}
        videoMobile={videos.seasonal_us.videoMobileUrl}
        annotations={videos.seasonal_us.annotations}
        title="Stream Live TV & Sports"
        subtitle="ON ANY DEVICE. ANYTIME. ANYWHERE."
        tagline="Watch and DVR the channels you love. No contracts, no commitment."
        showRedzone={false}
        newTemplate={true}
        isHeroBannerTest={true}
        // showHoliday={true}
        highlightImage="https://assets-imgx.fubo.tv/marketing/lp/images/holidays/Snow-G_LP_v3.png?ch=width&auto=format,compress"
        highlightHeadline={
          <>
            A HOLIDAY DEAL <br className="desktop-line-break" /> TO CHEER FOR
          </>
        }
        highlightShowHeadlineTop={true}
        highlightSubtitle={
          <>
            Enjoy deep savings on live sports, <br className="desktop-line-break" /> movies & shows
            this holiday season.
          </>
        }
        highlightBackground="linear-gradient(90deg,rgba(7, 5, 23, 1) 0%, rgba(7, 5, 23, 1) 20%,rgba(18, 24, 38, 1) 80%,rgba(18, 24, 38, 1) 100%)"
        highlightNote="*1st month. Taxes & fees may apply."
      />
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  .hero-new .hero-content {
    h1 {
      max-width: 220px;
      @media (min-width: 768px) {
        max-width: 440px;
        font-size: 68px;
      }
    }
    h2 {
      max-width: 220px;
      @media (min-width: 768px) {
        max-width: 440px;
      }
    }
  }

  .highlight-section {
    padding: 60px 0;
    overflow: hidden;

    @media (min-width: 982px) {
      padding: 0;
    }
    .highlight-section-inner {
      gap: 0px 0px;
      .CTAButton {
        border-radius: 3px;
        text-transform: none;
      }
      .highlight-section-image {
        max-width: 100%;
        img {
          transform: scale(1.05);
        }
        @media (min-width: 982px) {
          flex: 1 1 100%;
        }
        @media (max-width: 981px) {
          margin: 10px auto 10px;
        }
      }

      .highlight-section-content {
        min-width: 350px;
        @media (min-width: 982px) {
          padding: 30px 10px;
        }
      }
    }
  }

  .desktop-line-break {
    display: none;
    @media (min-width: 768px) {
      display: block;
    }
  }
`
