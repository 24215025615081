import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { leagueIds, sportIds } from 'src/constants/personalizationIds'
import { videos } from 'src/constants/videos'

export default props => {
  const { title, subtitle } = props

  let teamSubtitle

  if (props.team) {
    teamSubtitle = `SEE THE ${props.team.toUpperCase()} IN ACTION`
  }

  return (
    <USTemplateDefault
      location={props.location}
      lang="en"
      market="us"
      video={videos.cfb.videoUrl}
      title={title || 'Watch college football without cable'}
      subtitle={
        subtitle || teamSubtitle || 'Stream Big Ten, CBS, ACC, SEC, ESPN and 200+ live channels'
      }
      tagline={props.team ? 'Stream live games from any device' : null}
      networkSet="collegeFootball"
      // teams
      team={props.team}
      matchtickerSportId="111"
      matchtickerLeagueName="College Football"
      matchtickerLeagueId="191261"
      showRedzone={false}
      sportId={sportIds.Football}
      leagueId={leagueIds['NCAA Football']}
      {...props}
    />
  )
}
