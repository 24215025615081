import React, { useEffect } from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import { useDispatch } from 'react-redux'
import { updateParams } from 'src/modules/reducers/params'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'

export default props => {
  const dispatch = useDispatch()

  const params = {
    irad: '2140984',
    irmp: '5718522',
  }

  useEffect(() => {
    dispatch(updateParams(params))
  }, [])

  return (
    <StyledContainer>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <USTemplateDefault
        location={props.location}
        lang="en"
        market="us"
        offerBanner={
          <>
            ✔ Special offer applied! <br /> Save $30 on your first month of Fubo{' '}
          </>
        }
        offerCode={'us-recu-mk-pro_directv-mo-7d-30amtoff1-v1'}
        video={videos.seasonal_us.videoUrl}
        videoMobile={videos.seasonal_us.videoMobileUrl}
        annotations={videos.seasonal_us.annotations}
        title="Stream Live TV & Sports"
        subtitle="ON ANY DEVICE. ANYTIME. ANYWHERE."
        tagline="Watch and DVR the channels you love. No contracts, no commitment."
        showRedzone={false}
        redzoneOfferCode={'us-recu-mk-elite_sportp_directv-mo-7d-30amtoff1-v1'}
        newTemplate={true}
        hasDirectvPlanCards={true}
        disableFreeTrial={false}
        removeCable={true}
        ctaText={'Start your 7-day free trial'}
      />
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  .offer-banner {
    /* background-color: #003057; */
    background: linear-gradient(94.26deg, #02579f 35.69%, #0173d0 98.83%);
    padding-left: 8px;
    .banner-title {
      @media (max-width: 767px) {
        font-size: 15px;
      }
    }
    .spectrum-offer-banner {
      display: flex;
      align-items: center;
      justify-content: center;
      .offer-banner-image {
        padding-right: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 160px;
          @media (max-width: 767px) {
            width: 90px;
          }
        }
      }
      .offer-banner-text {
        padding-left: 16px;
        text-align: left;
        border-left: 1px solid #fff;
      }
    }
  }
`

// import React from 'react'
// import RedirectTemplate from 'src/components/template/redirect'
// export default props => <RedirectTemplate location={props.location} destination="/tv/" />
