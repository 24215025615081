import * as StacFlagsActionConstants from './cohorts/actionTypes'
import * as AnalyticsActionConstants from './analytics/actionsTypes'
import * as MarketActionConstants from './market/actionTypes'
import * as TranscendActionConstants from './transcend/actionTypes'
import * as ParamsActionConstants from './params/actionTypes'
import * as ImpactActionConstants from './impact/actionTypes'
import * as ZVAActionContants from './zva/actionTypes'
import { AnalyticsAction } from './analytics/actionsTypes'
import { StacFlagsAction } from './cohorts/actionTypes'
import { MarketAction } from './market/actionTypes'
import { TranscendAction } from './transcend/actionTypes'
import { ParamsAction } from './params/actionTypes'
import { ImpactAction } from './impact/actions'
import { ZVAAction } from './zva/actionTypes'

export type Action =
  | StacFlagsAction
  | AnalyticsAction
  | MarketAction
  | TranscendAction
  | ParamsAction
  | ImpactAction
  | ZVAAction

export const Actions = {
  ...StacFlagsActionConstants,
  ...AnalyticsActionConstants,
  ...MarketActionConstants,
  ...TranscendActionConstants,
  ...ParamsActionConstants,
  ...ImpactActionConstants,
  ...ZVAActionContants,
}

export type ActionName = keyof typeof Actions
export type ActionType = typeof Actions[keyof typeof Actions]
export const silentActions: Array<string | RegExp> = []
