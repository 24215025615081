import type { Product } from '@fubotv/types/api/UP/products'

import {
  FETCH_PRODUCT_BY_RATE_PLAN_FAIL,
  FETCH_PRODUCT_BY_RATE_PLAN_SUCCESS,
  FetchProductByRatePlanSuccessAction,
} from '../actionTypes/'
import { ApiError } from 'src/modules/utils/types/domain'

export const fetchProductByRatePlanSuccessful = (
  product: Product
): FetchProductByRatePlanSuccessAction => ({
  type: FETCH_PRODUCT_BY_RATE_PLAN_SUCCESS,
  product,
})

export const fetchProductByRatePlanFail = (error: ApiError) => ({
  type: FETCH_PRODUCT_BY_RATE_PLAN_FAIL,
  error,
})
