import React from 'react'
import LatinoTemplateDefault from 'src/components/template/la'
import { videos } from 'src/constants/videos'
import { sportIds, leagueIds } from 'src/constants/personalizationIds'
import { laDiscountPriceMonthly } from 'src/constants/laPlanData'
import RedirectTemplate from 'src/components/template/redirect'

//REPLACED: univision_removed
export default props => <RedirectTemplate location={props.location} destination="/latino/" />

//REMOVED: univision_removed
// export default props => {
//   return (
//     <LatinoTemplateDefault
//       location={props.location}
//       lang="en"
//       market="latino"
//       video={videos.liga_mx.videoUrl}
//       staticAnnotation={videos.liga_mx.staticAnnotation}
//       title="Watch Liga MX Live on TUDN"
//       subtitle={`Access now for only $${laDiscountPriceMonthly} the first month`}
//       tagline="STREAM ON YOUR PHONE, TV AND MORE"
//       networkSet="soccerLatino"
//       noteLink="/la/liga-mx-es/"
//       sportId={sportIds.Soccer}
//       leagueId={leagueIds['Liga MX']}
//       // matchtickerSportId="199"
//       // matchtickerLeagueName="Liga MX"
//       // Liga MX, La Liga, Champions League
//       // matchtickerLeagueId={['9208870', '3609994', '3616485']}
//     />
//   )
// }
