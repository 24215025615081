import { teamData } from 'src/constants/teamData'

export const fetchData = (postalCode, json) => {
  // check for postalCodes that start with 0
  if (postalCode[0] === '0') {
    postalCode = postalCode.slice(1)
  }
  const result = json[postalCode] ? json[postalCode] : null
  return result
}

// take rsnTeams and create a new array of objects with {team, region, league}, where team and region is from rsnTeams which is an array of {Region, Team 1, Team 2, Team 3, Team 4, Team 5, Team 6} where it is looping through each instance of team with a value (up to 6), and league is taken from the corresponding teamData value of the key that matches with team
export const listTeams = data => {
  const result = []
  data.forEach(rsn => {
    const teams = [
      rsn['Team 1'],
      rsn['Team 2'],
      rsn['Team 3'],
      rsn['Team 4'],
      rsn['Team 5'],
      rsn['Team 6'],
    ]
    teams.forEach(team => {
      if (team) {
        result.push(team)
      }
    })
  })
  return result
}

export const listRegions = data => {
  const result = []
  data.forEach(rsn => {
    result.push(rsn['Region'])
  })
  return result
}

export const createTeamData = data => {
  const result = []
  data.forEach(rsn => {
    const Region = rsn.Region
    const teams = [
      rsn['Team 1'],
      rsn['Team 2'],
      rsn['Team 3'],
      rsn['Team 4'],
      rsn['Team 5'],
      rsn['Team 6'],
    ]
    teams.forEach(team => {
      if (team) {
        const league = teamData[team]?.league
        const teamObj = { team: team, region: Region, league: league }
        result.push(teamObj)
      }
    })
  })
  return result
}

export const fetchRSNs = (postalCode, regionCode) => {
  return (
    fetch(`https://qa.fubo.tv/stream/data/rsn_data/${regionCode}.json`)
      // fetch(`http://local.fubo.tv:8000/data/rsn_data/${regionCode}.json`)
      .then(response => response.json())
      .then(data => {
        if (data[postalCode]?.rsns) {
          const eligibleRSN = data[postalCode]?.rsns
          const filteredRSN = [
            ...new Set(
              eligibleRSN.map(channel =>
                channel.startsWith('FanDuel') ? 'FanDuel Sports Network' : channel
              )
            ),
          ]
          return filteredRSN
        }
        return null
      })
      .catch(error => console.error('Error fetching data:', error))
  )
}
