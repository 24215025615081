import React from 'react'
import styled from 'styled-components'
import SEO from 'src/components/common/SEO'
import FAQSection from 'src/components/common/FAQSection'
import PlatformSection from 'src/components/common/PlatformSection'
import NetworkSection from 'src/components/common/NetworkSection'
import DealSection from 'src/components/common/DealSection'
import Layout from 'src/components/common/Layout'
import HeroSectionNew from 'src/components/common/HeroSectionNew'
import ChannelSection from 'src/components/common/ChannelSectionNew'

import { LogoSyncContextProvider } from 'src/components/common/AnnotationContext'
import { useSelector } from 'react-redux'
import { selectAnalyticsParameters } from 'src/modules/reducers/analytics'
import { createUrl } from 'src/modules/utils/createUrl'
import { Link } from 'gatsby'
import LAPlanCards from 'src/components/common/LAPlanCards'
import MatchTicker from 'src/components/common/MatchTicker'
import { fireUIInteractionEvent } from 'src/modules/analytics/v3'
import { selectStateForProtobuf } from 'src/modules/reducers'
import { offerCodes } from 'src/constants/offerCodes'
import { laDiscountPriceMonthly } from 'src/constants/laPlanData'
import Image from 'src/components/common/Image'

const CashAppFootnoteStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  white-space: nowrap;
  margin-top: 30px;
  margin-bottom: 15px;
  color: white;
  font-size: 18px;
  @media (min-width: 768px) {
    font-size: 20px;
  }
  img {
    width: 170px;
    margin-top: 0;
    margin-left: 15px;
    object-fit: contain;
    @media (max-width: 768px) {
      width: 130px;
    }
  }
`

const CashAppLogo = () => (
  <Image src="https://assets-imgx.fubo.tv/marketing/lp/images/apps/cash-app-logo-text-w.png?w=200" />
)

const LatinoTemplateDefault = ({
  lang = 'en',
  market = 'latino',
  ctaText,
  // Offer Code
  offerCode = offerCodes.latino,
  offerBanner,
  // Metadata
  pageTitle,
  pageDescription,
  // Hero Section
  title,
  subtitle,
  tagline,
  video,
  staticAnnotation,
  annotations,
  poster = 'https://assets-imgx.fubo.tv/marketing/lp/images/posters/AdobeStock_210894329.jpg?w=2048&ch=width&auto=format,compress',
  posterMobile,
  noteLink,
  // Plan Cards
  disablePlanCards,
  discountedPrice,
  cardFootnote,
  // Network Section
  networkHeadline,
  networkSet = 'entertainmentLatino',
  customNetworks,
  // Others
  team,
  // AB Test for MatchTicker
  matchtickerSportId,
  matchtickerLeagueName,
  matchtickerLeagueId,
  // Force Anchor Link
  forceAnchorLink,
  // Personalization Params
  sportId,
  leagueId,
  teamId,
  heroFootnote,
}) => {
  //REMOVED: univision_removed
  // let metaDescription =
  //   pageDescription ||
  //   (lang === 'es'
  //     ? 'Transmite canales de TUDN, Univision, beIN SPORTS y más con Fubo. No te pierdas ni un momento de los equipos y programas que más te gustan: visualízalos en directo y a la carta desde tu ordenador, smartphone, tableta, Roku, Fire TV, Apple TV y Chromecast.'
  //     : "Stream TUDN, Univision, beIN SPORTS channels and more with Fubo. Don't miss a moment of the teams and shows you love - watch live and on-demand from your desktop, smartphone, tablet, Roku, Fire TV, Apple TV and Chromecast.")

  //REPLACED: univision_removed
  let metaDescription =
    pageDescription ||
    (lang === 'es'
      ? 'Transmite canales de beIN SPORTS y más con Fubo. No te pierdas ni un momento de los equipos y programas que más te gustan: visualízalos en directo y a la carta desde tu ordenador, smartphone, tableta, Roku, Fire TV, Apple TV y Chromecast.'
      : "Stream beIN SPORTS channels and more with Fubo. Don't miss a moment of the teams and shows you love - watch live and on-demand from your desktop, smartphone, tablet, Roku, Fire TV, Apple TV and Chromecast.")

  const stateForProtobuf = useSelector(selectStateForProtobuf)
  const isAnchor = forceAnchorLink && !disablePlanCards ? '#select-plan' : null
  const originalParams = useSelector(selectAnalyticsParameters)
  let noteUrl
  if (noteLink) {
    noteUrl = createUrl(noteLink, originalParams)
  }

  const handleLocaleSwitch = () => {
    fireUIInteractionEvent(
      {
        element: noteUrl,
        component: 'lp_locale_switch_clicked',
      },
      stateForProtobuf
    )
  }

  let noteText =
    lang === 'es' ? (
      <Link to={noteUrl} onClick={handleLocaleSwitch}>
        To learn more in English, <span>click here</span>.
      </Link>
    ) : (
      <Link to={noteUrl} onClick={handleLocaleSwitch}>
        Para traducir a español, <span>haga clic aquí</span>.
      </Link>
    )

  heroFootnote = heroFootnote ?? (
    <CashAppFootnoteStyled>
      {lang === 'es' ? 'Ahora aceptamos' : 'Now accepting'}
      <CashAppLogo />
    </CashAppFootnoteStyled>
  )

  if (!offerBanner) {
    if (lang == 'es') {
      offerBanner = (
        <>✔ ¡Super ofertón! Accede ahora por solo ${laDiscountPriceMonthly} el primer mes</>
      )
    } else {
      offerBanner = (
        <>✔ Great offer! Access now for only ${laDiscountPriceMonthly} you first month</>
      )
    }
  }

  return (
    <Layout
      lang={lang}
      market={market}
      offerCode={offerCode}
      offerBanner={offerBanner}
      forceLang={lang === 'es' ? 'es' : false}
      sportId={sportId}
      leagueId={leagueId}
      teamId={teamId}
    >
      <LogoSyncContextProvider annotations={annotations}>
        <PageWrapper>
          <SEO
            lang={lang}
            market={market}
            title={pageTitle || title}
            description={metaDescription}
          />
          {matchtickerLeagueId && (
            <MatchTicker
              lang={lang}
              market={market}
              sportId={matchtickerSportId}
              leagueName={matchtickerLeagueName}
              leagueId={matchtickerLeagueId}
            />
          )}
          <HeroSectionNew
            lang={lang}
            market={market}
            heroBgVideo={video}
            title={title}
            subtitle={subtitle}
            tagline={tagline}
            staticAnnotation={staticAnnotation}
            posterImg={poster}
            ctaText={ctaText}
            noteText={noteUrl && noteText}
            footnote={heroFootnote}
            ctaHrefOverride={isAnchor}
            posterImgMobile={posterMobile}
          />
          {!disablePlanCards && (
            <LAPlanCards
              lang={lang}
              ctaText={ctaText}
              offerCode={offerCode}
              discountedPrice={discountedPrice}
              cardFootnote={cardFootnote}
            />
          )}
          <NetworkSection
            lang={lang}
            market={market}
            networkHeadline={networkHeadline}
            networkSet={networkSet}
            customNetworks={customNetworks}
            ctaHrefOverride={isAnchor}
            ctaText={ctaText}
          />
          <ChannelSection lang={lang} market={market} />
          <DealSection lang={lang} market={market} />
          <PlatformSection
            lang={lang}
            market={market}
            ctaHrefOverride={isAnchor}
            ctaText={ctaText}
          />
          <FAQSection lang={lang} market={market} team={team} />
        </PageWrapper>
      </LogoSyncContextProvider>
    </Layout>
  )
}

export default LatinoTemplateDefault

const PageWrapper = styled.div`
  .hero-content-text {
    h1 span {
      display: inline-block;
    }
  }
`
