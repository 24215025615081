export const images = {
  logo: {
    url:
      'https://assets-imgx.fubo.tv/marketing/lp/images/logos/Fubo_Primary_Logo_WT_RGB.png?w=300&ch=width&auto=format,compress',
    alt: 'Fubo Logo',
  },
  formula1: {
    url:
      'https://assets-imgx.fubo.tv/marketing/lp/images/posters/formula1-b.jpg?w=2048&ch=width&auto=format,compress',
  },
  corus: {
    url:
      'https://assets-imgx.fubo.tv/marketing/lp/images/posters/ca_entertainment_bg.jpg?w=2048&ch=width&auto=format,compress',
  },
}
