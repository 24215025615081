import { createSelector } from 'reselect'

export const selectAnalyticsState = state => state.analytics

export const selectAnalyticsReady = createSelector(
  selectAnalyticsState,
  state => state?.analyticsReady
)

export const selectAnalyticsPageViewSent = createSelector(
  selectAnalyticsState,
  state => state?.pageViewSent
)

export const selectAnalyticsProperties = createSelector(
  selectAnalyticsState,
  state => state?.properties
)

export const selectAnalyticsPropertiesReady = createSelector(
  selectAnalyticsState,
  state => state?.analyticsPropertiesReady
)

export const selectAnalyticsParameters = createSelector(
  selectAnalyticsState,
  state => state?.parameters
)

export const selectAnalyticsDevice = createSelector(
  selectAnalyticsProperties,
  properties => properties?.device_category
)
