import React from 'react'
import GolfTemplate from 'src/components/page/golf'
import { leagueGroupIds } from 'src/constants/personalizationIds'
import styled from 'styled-components'

export default props => (
  <StyledContainer>
    <GolfTemplate
      location={props.location}
      poster={'https://assets-imgx.fubo.tv/marketing/lp/images/posters/golf_Web_v3.png'}
      posterMobile={'https://assets-imgx.fubo.tv/marketing/lp/images/posters/golf_Mobile_v3.jpg'}
      title="Fubo has the most golf"
      subtitle={'Stream 120+ live events from the PGA Tour & more'}
      matchtickerLeagueId={leagueGroupIds.golf}
    />
  </StyledContainer>
)

const StyledContainer = styled.div``
