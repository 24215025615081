import { ofType, combineEpics } from 'redux-observable'
import { from, of } from 'rxjs'
import { switchMap, catchError, map } from 'rxjs/operators'

import { TRY_PAGE_LOAD, TryPageLoadAction } from './actionTypes.ts'
import { pageLoadFail, pageLoadSuccess } from './actions.ts'
import { impactRadiusPageLoad } from './effects.ts'
import { ActionsObservable, StateObservable } from '../index.ts'
import { getEnvironment } from 'src/config/env/env.js'

const impactPageLoadEpic = (
  action$: ActionsObservable,
  _state$: StateObservable,
  impactPageLoadFn = impactRadiusPageLoad
) =>
  action$.pipe(
    ofType(TRY_PAGE_LOAD),
    switchMap((action: TryPageLoadAction) => {
      const isProd = getEnvironment().env === 'production'

      const pageUrl = isProd
        ? window?.location?.href
        : `https://qa.fubo.tv${window?.location?.pathname}${window?.location?.search}`

      return from(
        impactPageLoadFn({
          PageUrl: pageUrl ?? 'https://www.fubo.tv',
          ReferringUrl: action.payload.referringUrl
            ? action.payload.referringUrl
            : document?.referrer?.includes('fubo.tv')
            ? ''
            : document.referrer,
          UserAgent: navigator.userAgent,
          CustomProfileId: action.payload.deviceId,
        })
      ).pipe(
        map((resp: any) => {
          return pageLoadSuccess(resp)
        }),
        catchError(error => {
          return of(
            pageLoadFail(
              (error && error.xhr && error.xhr.response) || error?.response?.body?.status
            )
          )
        })
      )
    })
  )

export const impactEpic = combineEpics(impactPageLoadEpic)
