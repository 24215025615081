import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { leagueIds, sportIds } from 'src/constants/personalizationIds'
import styled from 'styled-components'

const GolfTemplate = props => (
  <StyledContainer>
    <USTemplateDefault
      location={props.location}
      lang="en"
      market="us"
      poster={props.poster}
      posterMobile={props.posterMobile}
      title={props.title}
      subtitle={props.subtitle}
      sportId={sportIds.Golf}
      logo={props.logo}
      logoHeight={props.logoHeight}
      offerBanner={props.offerBanner}
      offerCode={props.offerCode}
      newTemplate={true}
      networkSet={'golf'}
      networkHeadline={'We have more golf than any other live TV provider'}
      dealSavingsText={<>More golf than anyone.</>}
      platformImage={
        'https://assets-imgx.fubo.tv/marketing/lp/images/devices/devices-golf-2025.png'
      }
      matchtickerSportId={sportIds.Golf}
      matchtickerLeagueName={'PGA Golf Tour'}
      matchtickerLeagueId={leagueIds['PGA Golf Tour']}
      {...props}
    />
  </StyledContainer>
)

export default GolfTemplate
const breakpoint = 768
const desktopBreak = breakpoint + 'px'
const mobileBreak = breakpoint - 1 + 'px'

const StyledContainer = styled.div`
  .content-container .hero-content {
    max-width: 610px;
  }
  .desktop-line-break {
    display: block;
    @media (min-width: ${desktopBreak}) {
      display: inline;
    }
  }

  .networks-row {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);

    @media (min-width: ${desktopBreak}) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }
    max-width: 800px;
  }
  .networks-item {
    aspect-ratio: 245 / 144;
    img {
      max-width: 90%;
      max-height: none;
    }
  }
`
