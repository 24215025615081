import React from 'react'
import { networkLogos } from 'src/constants/networkLogos'
import { isUSFreeTrial } from 'src/modules/utils/constants'

export const slingData = {
  competitor: 'Sling TV',
  competitorColor: '#00B9FF',
  heroData: {
    title: 'Looking for the best streaming service?',
    subtitle:
      'Compare Fubo & Sling TV on price, features, channels and add-ons to see which is better for you.',
  },
  generalCompareData: {
    heading: 'Compare Fubo vs. Sling TV',
    subheading:
      'Here’s a general look at both to help you determine which is the best streaming service for you.',
    listItems: ['Channel Count', 'Cloud DVR', 'Simultaneous Streams', 'Starting Price'],
    fuboList: ['185+', 'Unlimited hours', 'Up to 10', '$79.99/mo.*'],
    competitorList: ['30-50+', '50 hours', '1-4', '$40-$60/mo.*'],
  },
  prosConsData: {
    heading: 'Pros and Cons: Fubo vs. Sling TV',
    content: (
      <>
        <p>
          Fubo and Sling TV have a lot of the features cord cutters want from a cable replacement
          streaming service. They check a lot of the boxes like maintaining a relatively low price,
          offering a wide variety of live channels and including unique features cable companies
          just don’t have.
        </p>
        <p>
          Despite both offering a bunch of plans and add-on options to create a customized TV
          experience, they still have some extensive differences between them. Fubo is a more
          premium product and it has the price tag to match. Fubo plans are loaded with live
          channels (at least 185 live channels) and quality features. Every plan currently includes
          {isUSFreeTrial ? ' a free trial,' : ''} unlimited Cloud DVR storage and the ability to
          simultaneously stream on multiple devices (up to 10).
        </p>
        <p>
          Sling TV is definitely a cheaper product, but it’s at a significant cost to the channel
          count. Both the Orange and Blue plans from Sling TV lack many of the sports and
          entertainment channels under the CBS umbrella, which includes channels needed for football
          on Saturdays and Sundays. Sling TV has good features, but they sometimes have complicated
          restrictions such as only getting a free trial if you signed up on a specific platform or
          limiting the ability to simultaneously stream to select channels.
        </p>
      </>
    ),
  },
  plansData: {
    heading: 'Compare Fubo Pro plan vs. Sling TV Orange & Blue plan',
    subheading:
      'Let’s take a look at the differences between the popular plans for Fubo and Sling TV.',
    tableData: {
      Plan: ['Pro plan', 'Orange & Blue Plan'],
      Channels: ['185+ channels', '48+ channels'],
      'Monthly Price': ['$79.99', '$60'],
      'Hours of DVR storage': ['Unlimited', '50 hours'],
      'Free Trial': [isUSFreeTrial ? 'Yes' : 'No', 'Yes, with restrictions'],
      'Multiple active streams': ['Up to 10', 'Up to 4, with restrictions'],
      'Regional sports networks': [true, true],
      'Streaming from any device': [true, true],
    },
  },
  channelsData: {
    heading: 'Compare Fubo Channels vs. Sling TV Channels',
    subheading:
      'This channel comparison chart focuses on what’s available among the most-watched channels from 2023 in the Fubo Pro plan vs. Sling TV Orange & Blue plan.',
    networksData: [
      { network: networkLogos.abc, fubo: true, competitor: true },
      { network: networkLogos.cbs, fubo: true, competitor: false },
      { network: networkLogos.nbc, fubo: true, competitor: true },
      { network: networkLogos.fox, fubo: true, competitor: true },
      { network: networkLogos.espn, fubo: true, competitor: true },
      { network: networkLogos.mtv, fubo: true, competitor: false },
      { network: networkLogos.telemundo, fubo: true, competitor: false },
      { network: networkLogos.disneyjunior, fubo: true, competitor: false },
      { network: networkLogos.fs1, fubo: true, competitor: true },
      { network: networkLogos.ion, fubo: true, competitor: false },
      { network: networkLogos.univision, fubo: true, competitor: false },
      { network: networkLogos.hallmark, fubo: true, competitor: false },
      { network: networkLogos.nickelodeon, fubo: true, competitor: false },
      { network: networkLogos.usa, fubo: true, competitor: true },
      { network: networkLogos.oxygen, fubo: true, competitor: false },
    ],
  },
  completedChannelsData: {
    heading: 'Complete Channel Comparison',
    subheading:
      'See how the live channel lineups compare between Fubo Pro and Sling TV Orange & Blue.',
    content: (
      <>
        <p>
          Both the Fubo Pro plan and Sling TV Orange & Blue plan have an excellent channel lineup.
          Cord cutters can definitely find a nice mix of live sports, top TV shows, popular movies
          and breaking news with either direction. The big difference comes down to what fits your
          needs and the needs of your household.
        </p>
        {/*REMOVED: univision_removed*/}
        {/* <p>
          The live channel lineup for Fubo is a more robust offering, and it’s not even close. With
          185+ channels at $74.99, they’re actually the better value per channel overall. Fubo’s the
          superior streaming service for sports, which includes significantly better coverage during
          football season due to their CBS channels. Fubo is more than sports though. They have many
          of the most watched live TV channels. They have popular news networks like FOX News
          Channel and MSNBC as well as popular entertainment channels like USA Network, Hallmark
          Channel and ION. They even have Spanish-language channels like Telemundo and Univision.
        </p> */}
        {/* REPLACED: univision_removed */}
        <p>
          The live channel lineup for Fubo is a more robust offering, and it’s not even close. With
          185+ channels at $74.99, they’re actually the better value per channel overall. Fubo’s the
          superior streaming service for sports, which includes significantly better coverage during
          football season due to their CBS channels. Fubo is more than sports though. They have many
          of the most watched live TV channels. They have popular news networks like FOX News
          Channel and MSNBC as well as popular entertainment channels like USA Network, Hallmark
          Channel and ION. They even have Spanish-language channels like Telemundo.
        </p>
        <p>
          However, Sling TV, as we already covered, is the cheaper product, but not by that much.
          They do overlap with Fubo on a handful of the same top channels like NBC, ABC, FOX, ESPN,
          USA Network and others. As noted earlier, they are missing a significant portion of
          popular TV with the absence of the Paramount Media Networks, which includes CBS, MTV,
          Nickelodeon, CMT and more. However, they do have the WarnerMedia channels that Fubo
          doesn’t currently include in their lineup. Overall, Sling TV has a decent lineup of sports
          and entertainment, but it does require you having the Orange & Blue plan to enjoy it all.
        </p>
      </>
    ),
  },
  faqData: {
    qaData: [
      {
        id: 'fubo-faq-5',
        question: 'What channels does Fubo have over Sling TV?',
        answer: (
          <p>
            Fubo has the Paramount Media Networks which includes CBS, MTV, Nickelodeon, VH1, CMT and
            others. They also have channels like ION, Big Ten Network and Hallmark Channel.
          </p>
        ),
      },
    ],
  },
}
