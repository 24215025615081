import React from 'react'
import RedirectTemplate from 'src/components/template/redirect'
export default props => <RedirectTemplate location={props.location} destination="/tv/" />

// import React from 'react'
// import USTemplateDefault from 'src/components/template/us'
// import { Helmet } from 'react-helmet'
// import styled from 'styled-components'
// import { videos } from 'src/constants/videos'

// export default props => {
//   return (
//     <StyledContainer>
//       <Helmet>
//         <meta name="robots" content="noindex" />
//       </Helmet>
//       <USTemplateDefault
//         location={props.location}
//         lang="en"
//         market="us"
//         offerCode="us-recu-mk-pro_optimum-mo-7d-24amtoff2-v1"
//         offerBanner={
//           <>
//             ✔&#xfe0e; Optimum discount applied!
//             <br />
//             Optimum customers get 30% off your first two months
//           </>
//         }
//         video={videos.seasonal_us.videoUrl}
//         videoMobile={videos.seasonal_us.videoMobileUrl}
//         annotations={videos.seasonal_us.annotations}
//         title="Stream Your Local Sports & News"
//         subtitle="WATCH ABC, NBC, FOX, CBS, CW AND MORE"
//         tagline="No contract, no commitment. Regional restrictions apply."
//         logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-optimum.png?ch=width&auto=format,compress"
//         networkSubtitle={'Cancel online anytime.'}
//         hasOptimumPlanCards={true}
//         removeCable={true}
//         networkSet="baseball"
//         customNetworks={['abc', 'nbc', 'fox', 'espn', 'fs1', 'cw', 'cbs', 'espn2']}
//         showRSN={true}
//       />
//     </StyledContainer>
//   )
// }

// const StyledContainer = styled.div`
//   .offer-banner {
//     /* background-color: #003057; */
//     background: linear-gradient(94.26deg, #02579f 35.69%, #0173d0 98.83%);
//     padding-left: 8px;
//     .banner-title {
//       @media (max-width: 767px) {
//         font-size: 15px;
//       }
//     }
//     .spectrum-offer-banner {
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       .offer-banner-image {
//         padding-right: 16px;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         svg {
//           width: 160px;
//           @media (max-width: 767px) {
//             width: 90px;
//           }
//         }
//       }
//       .offer-banner-text {
//         padding-left: 16px;
//         text-align: left;
//         border-left: 1px solid #fff;
//       }
//     }
//   }

//   .content-container .hero-content {
//     max-width: 600px;
//   }
// `

// // import React from 'react'
// // import RedirectTemplate from 'src/components/template/redirect'
// // export default props => <RedirectTemplate location={props.location} destination="/tv/" />
