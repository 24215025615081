// @flow
import {
  Store,
  Dispatch,
} from 'redux'
import type { Observable } from 'rxjs'

import type { MarketState } from './market'

import { Action } from './actionTypes'
import { StateObservable as ReduxStateObservable } from 'redux-observable'
import { StacFlagsState } from './cohorts/reducer'
import { ParamsState } from './params'
import { ImpactState } from './impact/reducer'
import { ZvaState } from './zva/reducer'
import { ProductsState } from './products'
import { TranscendState } from './transcend/reducers'

export type AppState = {
  cohorts: StacFlagsState,
  market: MarketState,
  params: ParamsState,
  impact: ImpactState
  zva: ZvaState,
  products: ProductsState,
  transcend: TranscendState
}

export type AppStore = Store<AppState, Action>
export type AppDispatch = Dispatch<Action>
export type StateObservable = ReduxStateObservable<AppState>
export type ActionsObservable = Observable<Action>

export const selectUPState = (state: AppState): ProductsState => state.products
export const selectStateForProtobuf = state => ({
  market: state.market,
  cohorts: state.cohorts,
  analytics: state.analytics,
})
export const selectImpactState = (state: AppState): ImpactState => state.impact

