import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import { leagueGroupIds, sportIds } from 'src/constants/personalizationIds'
export default props => (
  <USTemplateDefault
    location={props.location}
    lang="en"
    market="us"
    addonCode="intl-sports-plus"
    video={videos.cricket.videoUrl}
    title="Watch live cricket matches"
    subtitle="CATCH THE BIGGEST EVENTS OF THE YEAR"
    tagline="Stream them live from your TV & other devices"
    customNetworks={['willow']}
    sportId={sportIds.Cricket}
    matchtickerSportId={sportIds.Cricket}
    matchtickerLeagueName="Cricket"
    matchtickerLeagueId={leagueGroupIds.cricket}
  />
)
