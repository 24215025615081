import * as React from 'react'
import styled from 'styled-components'

import { useLocation } from '@reach/router'
import { getURIParamValue } from 'src/modules/utils/analytics'
import { isValidVideoSrc } from 'src/modules/utils/media'
import { LogoSyncContext } from 'src/components/common/AnnotationContext'
import { getImageName } from 'src/modules/utils/files'
import { imgIX } from 'src/modules/utils/imgIX'

const baseVideoSrcPath = 'https://assets.fubo.tv/marketing/lp/videos/compressed'
const baseVideoMobileSrcPath = 'https://assets.fubo.tv/marketing/lp/videos/mobile'
const baseVideoFrameSrcPath = 'https://assets-imgx.fubo.tv/marketing/lp/videos/firstframe'
// const posterImgDefault = "https://assets-imgx.fubo.tv/welcome/us/june_2021/us_june_ff.png?auto=format,compress"
// const posterImgDefault = "src/assets/images/poster/black-pixel.png"

const HeroVideo = ({
  heroBgVideo,
  heroBgVideoMobile,
  posterImg,
  posterImgMobile,
  containVideo = false,
  noVideo,
}) => {
  const location = useLocation() || (typeof window !== 'undefined' && window.location)

  const [stateBgVideo, setStateBgVideo] = React.useState('')
  const [statePosterImg, setStatePosterImg] = React.useState('')
  const [isMobile, setIsMobile] = React.useState(false)

  const videoRef = React.useRef(null)
  const videoObserver = React.useRef()
  const contextValue = React.useContext(LogoSyncContext)
  const newBgVideoURI = getURIParamValue(location.search, 'bgvideo')
  const checkIsMobile = () => {
    return window.innerWidth <= 768
  }

  React.useEffect(() => {
    function handleResize() {
      setIsMobile(checkIsMobile())
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const parseVideoName = url => {
    // Split the URL by '/'
    var parts = url.split('/')
    // Get the last part of the URL, which should be the file name
    var videoName = parts[parts.length - 1]
    // Return the file name
    return videoName
  }

  const checkVideoMobile = videoUrl => {
    let videoSrc = videoUrl
    checkIsMobile() &&
      (videoSrc =
        getVideoParamSrc() ||
        heroBgVideoMobile ||
        baseVideoMobileSrcPath + '/' + parseVideoName(videoSrc))
    return videoSrc
  }

  const getVideoParamSrc = () => {
    if (isValidVideoSrc(newBgVideoURI)) {
      return `${baseVideoSrcPath}/${newBgVideoURI}`
    }
    return false
  }

  const getFirstFrame = videoSrc => {
    if (videoSrc !== undefined) {
      let poster =
        baseVideoFrameSrcPath +
        '/' +
        getImageName(videoSrc) +
        '?w=2048&ch=width&auto=format,compress'
      checkIsMobile() && (poster += '&ar=1:1&fit=crop')
      return poster
    }
  }

  React.useEffect(() => {
    // Checks for the custom bgvideo query param used by marketing to override the background video

    // Select Video
    if (isValidVideoSrc(newBgVideoURI)) {
      const videoSrc = `${baseVideoSrcPath}/${newBgVideoURI}`
      setStateBgVideo(checkVideoMobile(videoSrc))
    } else {
      heroBgVideo && setStateBgVideo(checkVideoMobile(heroBgVideo))
    }

    // Select Poster
    if (isValidVideoSrc(newBgVideoURI)) {
      const videoSrc = `${baseVideoSrcPath}/${newBgVideoURI}`
      setStatePosterImg(getFirstFrame(videoSrc))
    } else {
      const posterImgCheck = isMobile && posterImgMobile ? posterImgMobile : posterImg
      const poster = posterImgCheck
        ? imgIX(posterImgCheck, isMobile ? { ar: '1:1', fit: 'crop' } : { w: 2048 })
        : heroBgVideo && getFirstFrame(heroBgVideo)
      setStatePosterImg(poster)
    }
  }, [posterImg, posterImgMobile, heroBgVideo, isMobile])

  React.useEffect(() => {
    if (videoRef.current) {
      const video = videoRef.current
      video.ontimeupdate = event => {
        contextValue.onTimeUpdate(video.currentTime)
      }
    }
  }, [stateBgVideo])

  React.useEffect(() => {
    if ('IntersectionObserver' in window) {
      videoObserver.current = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const video = entry.target
            const videoSource = video.querySelector('source')
            if (videoSource && videoSource.dataset.src) {
              videoSource.src = videoSource.dataset.src
              video.load()
              video.classList.remove('lazy')
              videoObserver.current.unobserve(video)
            }
          }
        })
      })

      const videoEl = videoRef.current
      if (videoEl) {
        videoObserver.current.observe(videoEl)
      }

      return () => {
        if (videoEl) {
          videoObserver.current.unobserve(videoEl)
        }
      }
    }
  }, [stateBgVideo])

  return (
    <VideoWrapper heroBgVideo={heroBgVideo} poster={statePosterImg} className="videoWrapper">
      <VideoElement
        className={containVideo ? 'containVideo lazy' : 'coverVideo lazy'}
        ref={videoRef}
        // change to a var that lets me switch between dekstop and mobile
        key={!noVideo && stateBgVideo}
        // change to a var that lets me switch between dekstop and mobile, so that the image is imgx cropped
        // poster={statePosterImg}
        loop
        muted
        autoPlay
        playsInline
      >
        {!noVideo && <source data-src={stateBgVideo} type="video/mp4" />}
      </VideoElement>
    </VideoWrapper>
  )
}

export default HeroVideo

const VideoWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  box-sizing: border-box;
  z-index: 2;
  background-color: #000;
  background-image: ${props => (props.poster ? `url(${props.poster})` : 'none')};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`

const VideoElement = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  object-fit: cover;

  &.containVideo {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    object-fit: contain;
  }
`
