import React from 'react'
import GolfTemplate from 'src/components/page/golf'
import styled from 'styled-components'
export default props => (
  <StyledContainer>
    <GolfTemplate
      location={props.location}
      poster={'https://assets-imgx.fubo.tv/marketing/lp/images/posters/golf_Web_v3.png'}
      posterMobile={'https://assets-imgx.fubo.tv/marketing/lp/images/posters/golf_Mobile_v3.jpg'}
      title="Fubo has the most golf"
      subtitle={'Stream 120+ live events from the PGA Tour & more'}
      offerBanner={<>✔ Enter your unique code to get 30 days FREE from Epic Golf Club.</>}
      logo={'https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-epic-golf.png'}
      logoHeight={'80px'}
      hasOfferInput={true}
    />
  </StyledContainer>
)

const StyledContainer = styled.div``
