import React, { useEffect, useState } from 'react'
import LatinoTemplateDefault from 'src/components/template/la/'
import { videos } from 'src/constants/videos'
import styled from 'styled-components'

export default props => {
  return (
    <StyledContainer>
      <LatinoTemplateDefault
        location={props.location}
        lang="es"
        market="latino"
        title={
          <>
            Mira LaLiga <span className="desktop-line-break">en vivo </span>{' '}
          </>
        }
        pageTitle="Mira LaLiga en vivo"
        tagline={
          <>
            Sigue los partidos en ESPN Deportes
            <span className="desktop-line-break"> desde cualquier dispositivo con Fubo</span>
          </>
        }
        networkSet="entertainmentLatino"
        noteLink="/la/la-liga-on-espn-deportes/"
        poster="https://assets-imgx.fubo.tv/marketing/lp/images/posters/LaLiga_Ver1_Web.jpg"
        posterMobile="https://assets-imgx.fubo.tv/marketing/lp/images/posters/LaLiga_Ver1_Mobile.jpg"
      />
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  .desktop-line-break {
    display: inline;
    @media (min-width: 768px) {
      display: block;
    }
  }
`
