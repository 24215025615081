import { fetchChannelsByLocation } from '@fubotv/js-client/dist/esm/domains/UP/channels/fetchChannelsByLocation'
import {
  fetchProducts,
  fetchProductByRatePlan,
} from '@fubotv/js-client/dist/esm/domains/UP/subscriptions/products'

import type { Params as FetchChannelsByLocationParams } from '@fubotv/js-client/dist/esm/domains/UP/channels/fetchChannelsByLocation'
import type { Params as FetchProductsParams } from '@fubotv/js-client/dist/esm/domains/UP/subscriptions/products/fetchProducts'
import type { Product, LiteProducts } from '@fubotv/types/api/UP/products'

// import { getGgHost } from '~/utils/host/'

import { TryFetchProductByRatePlanAction } from './actionTypes/'
// import { call } from '../api-client'
import { call } from '../js-client'

export function doFetchProductByRatePlan(
  action: TryFetchProductByRatePlanAction
): Promise<Product> {
  return call(fetchProductByRatePlan, action.params)
}

export function doFetchProducts(
  params: FetchProductsParams
): Promise<LiteProducts> {
  return call(fetchProducts, params)
}

export function doFetchChannelsByLocation(
  params: FetchChannelsByLocationParams
): Promise<any> {
  return call(fetchChannelsByLocation, params)
}

// export function getFreeTrialAvailableData(
//   countryCode: string,
//   postalCode?: string,
//   seriesId?: number,
//   networkId?: number,
//   deviceId?: string
// ) {
//   const url = `${getGgHost()}/products/has-free-trial`
//   const uniqueId = deviceId || getUniqueIdCookie()

//   return (
//     request
//       .get(url)
//       .query({ seriesId: seriesId, networkId: networkId })
//       /* 
//         We need to pass these headers in order for GG to interface with the various services to respond correctly.
//       */
//       .set('x-country-code3', countryCode)
//       .set('x-postal-code', postalCode || '')
//       .set('x-device-id', uniqueId)
//       .then((resp) => {
//         return resp.body
//       })
//   )
// }
