import React, { useEffect, useState } from 'react'
import CanadaTemplateDefault from 'src/components/template/ca/'
import { leagueIds, sportIds } from 'src/constants/personalizationIds'
import { videos } from 'src/constants/videos'
import { useEPLImage } from 'src/modules/hooks/useEPLImage'
import styled from 'styled-components'

export default props => {
  const posterImg = useEPLImage()

  return (
    <StyledContainer>
      <CanadaTemplateDefault
        location={props.location}
        lang="en"
        market="canada"
        // video={videos.canada_epl.videoUrl}
        // Static Image
        poster={posterImg}
        noVideo={true}
        pageTitle="Watch the Premier League all season"
        title={<>Watch the Premier League all season</>}
        subtitle="LIVE GAMES AVAILABLE IN 4K ULTRA HD"
        tagline="Streaming exclusively on Fubo"
        plan="soccer"
        networkSet="canada"
        logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-lockup-epl-white.png?ch=width&auto=format,compress"
        logoHeight="90px"
        matchtickerSportId={sportIds.Soccer}
        matchtickerLeagueName="Premier League"
        matchtickerLeagueId={leagueIds['Premier League']}
      />
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  .desktop-line-break {
    display: inline-block;
    @media (min-width: 768px) {
      display: block;
    }
  }
`
