import React from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import { sportIds, leagueIds } from 'src/constants/personalizationIds'
import { useCheckFanduelRSN } from 'src/modules/utils/useCheckFanduelRSN'

export default props => {
  const [showFDPlanCards, rsnData] = useCheckFanduelRSN()
  const hasNhl = rsnData?.teams?.includes('Tampa Bay Lightning')

  return (
    <USTemplateDefault
      location={props.location}
      lang="en"
      market="us"
      pageDescription="Stream NHL games in 4K with Fubo. Do not miss a moment of the teams you love - watch from your desktop, smartphone, tablet, Roku, Fire TV, Apple TV and Chromecast."
      video={videos.nhl.videoUrl}
      staticAnnotation={videos.nhl.staticAnnotation}
      title="Watch the NHL on ESPN"
      subtitle="WATCH GAMES ALL SEASON LONG"
      tagline="Stream them live from your TV, phone and other devices"
      ctaText="Start Watching"
      networkSet="hockey"
      league="nhl"
      showRSN={true}
      sportId={sportIds.Hockey}
      leagueId={leagueIds.NHL}
      matchtickerSportId={sportIds.Hockey}
      matchtickerLeagueName="NHL"
      matchtickerLeagueId={leagueIds.NHL}
      hasFanduelPlanCards={showFDPlanCards && hasNhl}
    />
  )
}
