import React, { useEffect } from 'react'
import USTemplateDefault from 'src/components/template/us'
import { videos } from 'src/constants/videos'
import { useDispatch } from 'react-redux'
import { updateParams } from 'src/modules/reducers/params'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'

const SpectrumLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="229.124"
    height="47.867"
    viewBox="0 0 229.124 47.867"
  >
    <defs>
      <style>{`.a{fill:none;}.b{clip-path:url(#a);}.c{fill:#fff;}.d{fill:#0099d8;}`}</style>
      <clipPath id="a">
        <rect className="a" width="229.124" height="47.867" />
      </clipPath>
    </defs>
    <g className="b">
      <path
        className="c"
        d="M219.6,359.319q3.568,3.047,10.711,3.044,6.934,0,10.712-2.939A10.851,10.851,0,0,0,244.8,350.6a9.773,9.773,0,0,0-1.155-4.832,8.467,8.467,0,0,0-3.535-3.377,15.312,15.312,0,0,0-2.75-1.062c-1.289-.393-7.694-2.14-8.341-2.313-3.57-.869-5.835-2.058-5.852-4.17.185-4.082,3.991-4.41,6.092-4.41a8.639,8.639,0,0,1,5.146,1.366,4.731,4.731,0,0,1,2.008,4h7.128a11.669,11.669,0,0,0-3.781-8.51q-3.467-2.94-9.872-2.94-6.827,0-10.4,2.835a9.911,9.911,0,0,0-3.57,8.085c.011,4.408,2.1,7,3.99,8.192a24.988,24.988,0,0,0,7.141,2.415l2.839.618c4.924,1.2,7.75,2.722,7.661,5.157a3.94,3.94,0,0,1-1.68,3.36,9.062,9.062,0,0,1-4.933,1.156,9.935,9.935,0,0,1-5.777-1.471,5.764,5.764,0,0,1-2.415-4.412H215.4q.316,5.987,4.2,9.032"
        transform="translate(-215.4 -324.35)"
      />
      <path
        className="c"
        d="M251.6,344.053c0-2.744-.5-8.549-6.119-8.549-5.333,0-5.91,5.664-5.91,8.549,0,7.3,4.151,8.232,6.121,8.232,5.1,0,5.908-5.412,5.908-8.232m-12.029,20.685a3.559,3.559,0,0,1-3.588,3.588H232.4V342.048c.416-6.992,2.959-12.564,13.578-12.944a12.91,12.91,0,0,1,9.9,4.188c2.111,2.744,3.015,6.154,3.015,10.445q0,6.647-3.166,10.763a9.9,9.9,0,0,1-8.127,4.011,12.954,12.954,0,0,1-8.025-2.453c0,.24,0,8.679,0,8.679"
        transform="translate(-201.491 -320.459)"
      />
      <path
        className="c"
        d="M261.008,352.16c-1.818-.093-5.283-.449-5.6-6.51l18.2,0c0-.185,0-2.18.005-2.184-.125-4.566-1.22-7.972-3.36-10.525q-3.467-3.78-9.767-3.781-5.984,0-9.345,3.884-3.257,3.78-3.255,10.5,0,7.037,3.36,10.816,3.358,3.887,9.343,3.886a13.366,13.366,0,0,0,8.192-2.415,11.488,11.488,0,0,0,4.421-6.73l-6.323,0c-.931,2.639-3.31,3.182-5.868,3.053m-.315-16.908c4.512-.06,5.213,3.781,5.355,5.67H255.338s-.091-5.57,5.355-5.67"
        transform="translate(-188.815 -320.412)"
      />
      <path
        className="c"
        d="M281.34,349.115h6.9s-1.875,9.134-12.3,9.134-12.993-8.368-12.993-13.866.976-14.889,12.587-15.219c10.729-.16,12.5,9.134,12.5,9.134h-7.348s-.713-3.142-5.088-3.042-5.517,4.206-5.517,8.11.971,8.792,5.828,8.792c4.619.14,5.026-2.357,5.434-3.044"
        transform="translate(-176.485 -320.411)"
      />
      <path
        className="c"
        d="M285.014,340.661h4.317l0-7.361h-4.306v-4.724A3.369,3.369,0,0,0,281.557,325h-3.779v30.873c0,3.22,1.247,5.146,3.951,5.146l3.286,0Z"
        transform="translate(-164.347 -323.815)"
      />
      <path
        className="c"
        d="M291.67,357.414V343.029a6.3,6.3,0,0,1,1.469-4.621,6.144,6.144,0,0,1,4.515-1.469h2.417v-7.457a25.44,25.44,0,0,0-6.563.736,13.8,13.8,0,0,0-4.673,2.309,11.346,11.346,0,0,0-3.308,4.462,17.293,17.293,0,0,0-1.209,6.879v13.546Z"
        transform="translate(-158.995 -320.153)"
      />
      <path
        className="c"
        d="M294.124,329.5h7.352v17.852a4.525,4.525,0,0,0,4.83,4.832,4.59,4.59,0,0,0,4.932-4.626V332.969s.249-3.464,3.437-3.464h3.813s-.005,16.422,0,16.581c-.051.9.964,11.884-12.18,11.84-13.022,0-12.1-11.109-12.182-11.763Z"
        transform="translate(-150.969 -320.131)"
      />
      <path
        className="c"
        d="M333.138,357.81h-7.281V339.869c0-2.068-.853-4.855-4.963-4.855a4.556,4.556,0,0,0-4.852,4.855V357.81h-7.39V341.346c0-4.783,1.42-7.7,3.748-9.6,2.306-1.878,5.259-2.5,8.283-2.639a21.389,21.389,0,0,1,4.75.527,10.006,10.006,0,0,1,4.222,2.111,9.352,9.352,0,0,1,3.746-1.953,17.739,17.739,0,0,1,4.486-.686c9.01-.047,12.627,4.079,12.927,5.4l0,23.3h-4.172a3.533,3.533,0,0,1-3.586-3.588V339.869c.018-2.035-.695-4.855-4.963-4.855-4.221,0-4.959,3.166-4.959,4.855Z"
        transform="translate(-139.078 -320.459)"
      />
      <path
        className="d"
        d="M332.965,332.068V355.35L348.3,343.712Z"
        transform="translate(-119.18 -318.033)"
      />
    </g>
  </svg>
)

export default props => {
  const dispatch = useDispatch()

  const params = {
    irad: '366904',
    irmp: '4742846',
    lang: 'es',
  }

  useEffect(() => {
    dispatch(updateParams(params))
  }, [])

  return (
    <StyledContainer>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <USTemplateDefault
        location={props.location}
        lang="es"
        market="us"
        // addon with ESPN Deportes
        addonCode="intl-sports-plus"
        offerCode="pro-fubo-basic-v2-spectrum2"
        offerBanner={
          <>
            {/* <div className="spectrum-offer-banner">
             <div className="offer-banner-image">
              <SpectrumLogo />
            </div> */}
            {/* <div className="offer-banner-text"> */}
            ✔ ¡Tu descuento de Spectrum esta aplicado!
            <br />
            Oferta especial con tu prueba gratuita.
            {/* </div>
          </div> */}
          </>
        }
        video={videos.seasonal_us.videoUrl}
        videoMobile={videos.seasonal_us.videoMobileUrl}
        annotations={videos.seasonal_us.annotations}
        title="Haz streaming de TV en vivo, deportes y contenido On Demand"
        //REMOVED: univision_removed
        // subtitle="Disfruta de ESPN Deportes, TUDN, Univision y más canales en vivo"
        //REPLACED: univision_removed
        subtitle="Disfruta de ESPN Deportesy más canales en vivo"
        tagline="Sin contratos, sin compromiso."
        // logo="https://assets-imgx.fubo.tv/marketing/lp/images/partners/logo-spectrum.png?ch=width&auto=format,compress"
        // logoHeight="80px"
        ctaText="Pruébalo Gratis"
        // networkSubtitle={'Cancel online anytime.'}
        hasCharterPlanCards={true}
        removeCable={true}
        noteLink="/offer"
      />
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  .offer-banner {
    /* background-color: #003057; */
    background: linear-gradient(94.26deg, #02579f 35.69%, #0173d0 98.83%);
    padding-left: 8px;
    .banner-title {
      @media (max-width: 767px) {
        font-size: 15px;
      }
    }
    .spectrum-offer-banner {
      display: flex;
      align-items: center;
      justify-content: center;
      .offer-banner-image {
        padding-right: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 160px;
          @media (max-width: 767px) {
            width: 90px;
          }
        }
      }
      .offer-banner-text {
        padding-left: 16px;
        text-align: left;
        border-left: 1px solid #fff;
      }
    }
  }
`

// import React from 'react'
// import RedirectTemplate from 'src/components/template/redirect'
// export default props => <RedirectTemplate location={props.location} destination="/tv/" />
