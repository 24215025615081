import React from 'react'
import CanadaTemplateDefault from 'src/components/template/ca'
import { leagueGroupIds, sportIds } from 'src/constants/personalizationIds'
import { videos } from 'src/constants/videos'
import styled from 'styled-components'
export default props => (
  <StyledContainer>
    <CanadaTemplateDefault
      location={props.location}
      lang="en"
      market="canada"
      addonCode="sports-plus-ca"
      video={videos.cricket.videoUrl}
      title="Watch live cricket matches"
      subtitle="CATCH THE BIGGEST EVENTS OF THE YEAR"
      tagline="Stream them live from your TV & other devices"
      networkSet="canada"
      plan="soccer"
      planCardOverride="cricket"
      soccerPlanCardImage="https://assets-imgx.fubo.tv/marketing/lp/images/card/canada/ca_card_cricket.jpg?w=750&ch=width&auto=format,compress"
      customNetworks={['willow']}
      sportId={sportIds.Cricket}
      matchtickerSportId={sportIds.Cricket}
      matchtickerLeagueName="Cricket"
      matchtickerLeagueId={leagueGroupIds.cricket}
      forcePlanProduct="Sports"
      selectedPlanLengths={['Quarterly', 'Annual']}
    />
  </StyledContainer>
)

const StyledContainer = styled.div`
  .content-container .hero-content {
    max-width: 600px;
  }
`
