import React, { useEffect, useState } from 'react'
import LatinoTemplateDefault from 'src/components/template/la/'
import { videos } from 'src/constants/videos'
import styled from 'styled-components'

export default props => {
  return (
    <StyledContainer>
      <LatinoTemplateDefault
        location={props.location}
        lang="en"
        market="latino"
        title="Watch live LaLiga games"
        tagline={
          <>
            Stream them on ESPN Deportes{' '}
            <div className="desktop-line-break">from any device with Fubo</div>
          </>
        }
        networkSet="entertainmentLatino"
        noteLink="/la/la-liga-es-on-espn-deportes/"
        poster="https://assets-imgx.fubo.tv/marketing/lp/images/posters/LaLiga_Ver1_Web.jpg"
        posterMobile="https://assets-imgx.fubo.tv/marketing/lp/images/posters/LaLiga_Ver1_Mobile.jpg"
      />
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  .desktop-line-break {
    display: inline;
    @media (min-width: 768px) {
      display: block;
    }
  }
`
